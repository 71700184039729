
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import Card from "primevue/card";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";

export default defineComponent({
  name: "GLAttachmentsTab",
  components: {
    Card,
    AttachmentRecords,
  },
  created() {
    this.id = this.$route.params.transaction_id as string;
    if(!this.id) {
      this.$router.push("/accounting/gl/")
    }
  },
  data() {
    return {
      id: '',
    }
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "accountingInquiry/getActiveOrderTab",
    })
  },
  methods: {
    handleDeleted(event: any) {
      if (event.success) {
        this.getActiveOrderTab(this.id).record.attachments_items = this.getActiveOrderTab(this.id).record.attachments_items.filter((item: { attachments: any; }) => {
          return item.attachments !== event.attachmentId;
        });
      }
      
      (this.$refs.attachmentRecords as any).fetchAttachmentRecords();
    },
    handleSaved(event: any) {
      if (!this.getActiveOrderTab(this.id).record.attachments_items) {
        this.getActiveOrderTab(this.id).record.attachments_items = [];
      }
      this.getActiveOrderTab(this.id).record.attachments_items.push({attachments: event.attachmentId});
      (this.$refs.attachmentRecords as any).fetchAttachmentRecords();
    }
  }
});
